<template>
  <div v-if="enabled" class="button-block">
    <button
      v-if="isPrimaryCta && displayAllowed"
      :class="'primary-cs-button buy-button ' + statusResponse.ctaType"
      :style="isDefaulted ? style : primaryCTAStyle"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      {{ btnName }}
    </button>
    <div v-else-if="isRegularCta && displayAllowed" :style="paymentBlockStyle">
      <button
        :class="'secondary-cs-button buy-button ' + statusResponse.ctaType"
        :style="secondaryBtnStyle"
        @mouseover="hover = true"
        @mouseleave="hover = false"
      >
        {{ btnName }}
      </button>
    </div>
    <div
      v-else-if="isPaymentPreviewCta && displayAllowed"
      :style="paymentBlockStyle"
    >
      <fieldset class="outlined-div" :style="paymentStyle">
        <legend class="outlined-div-label">
          {{ this.capitalized(ctaType) }}
        </legend>
        <div class="monthly-payment">
          <span>$</span>
          <span>{{ paymentAmount }}</span>
          <span class="monthly-label">/mo</span>
        </div>

        <button
          class="secondary-cs-button"
          @mouseover="hover = true"
          @mouseleave="hover = false"
          :style="paymentBtnStyle"
        >
          Personalize Payments
        </button>
      </fieldset>
    </div>
  </div>
</template>

<script>
import lodash from "lodash";
import "./showComponentOnLoad";

export default {
  name: "CtaButton",
  props: {
    statusResponse: {
      active: false,
      dealerEnabled: true,
      domain: null,
      dealerSlug: "",
      dealerId: null,
      url: null,
      ctaType: null,
    },
    dev: {
      type: Boolean,
      required: false,
      default: false,
    },
    width: {
      type: String,
      required: false,
      default: null,
    },
    height: {
      type: String,
      required: false,
      default: null,
    },
    fontSize: {
      type: String,
      required: false,
      default: null,
    },
    font: {
      type: String,
      required: false,
      default: null,
    },
    fontColor: {
      type: String,
      required: false,
      default: "#fff",
    },
    textAlign: {
      type: String,
      required: false,
      default: "center",
      validator: function (value) {
        return (
          ["CENTER", "LEFT", "RIGHT"].indexOf(lodash.toUpper(value)) !== -1
        );
      },
    },
    padding: {
      type: String,
      required: false,
      default: "10px",
    },
    margin: {
      type: String,
      required: false,
      default: null,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "#c3002f",
    },
    hoverBackgroundColor: {
      type: String,
      required: false,
      default: "#9d0026",
    },
    test: {
      type: Boolean,
      required: false,
      default: false,
    },
    borderRadius: {
      type: String,
      required: false,
      default: null,
    },
    details: {
      type: Boolean,
      required: false,
      default: false,
    },
    overlayEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    thirdPartyCookiesEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    wideView: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    ctaType() {
      const ctaType = lodash.get(this.statusResponse, "ctaType", "");
      return ctaType;
    },
    ctaStyle() {
      const ctaSType = lodash.get(this.statusResponse, "style", null);
      return ctaSType;
    },
    btnName() {
      const responseValues = this.ctaStyle;
      const name = lodash.get(responseValues, "name", null);

      if (!lodash.isNil(name)) {
        return name;
      } else {
        switch (this.ctaType) {
          case "trade":
            return "Get Trade Value";
          case "pre-approval":
            return "Get Pre-approved";
          default:
            return "Buy@Home";
        }
      }
    },
    paymentAmount() {
      const paymentAmount = lodash.get(
        this.statusResponse,
        "paymentToDisplay",
        0
      );
      return paymentAmount;
    },
    isPaymentPreviewCta() {
      let isDetailsPage = this.details;
      let isValidCta = this.ctaType === "lease" || this.ctaType === "finance";
      let hasPayment = this.paymentAmount > 0;
      return isDetailsPage && isValidCta && hasPayment;
    },
    isPrimaryCta() {
      let isValidCta = this.ctaType === "primary";
      return isValidCta;
    },
    isRegularCta() {
      let isValidCta =
        this.ctaType !== null &&
        this.ctaType !== "lease" &&
        this.ctaType !== "finance" &&
        this.ctaType !== "primary";
      return isValidCta;
    },
    enabled() {
      if (this.test) {
        return true;
      }

      const active = lodash.get(this.statusResponse, "active", false);
      const domain = lodash.get(this.statusResponse, "domain", null);

      let isValidDomain = !lodash.isNil(domain);
      if (
        isValidDomain &&
        this.dev === false &&
        domain.indexOf("localhost") !== -1
      ) {
        // eslint-disable-next-line
        console.log("cs-buy-widget invalid domain:", domain);
        isValidDomain = false;
      }

      return active === true && isValidDomain;
    },
    style() {
      const style = {
        color: this.escapeBadCharacters(this.fontColor),
      };

      if (this.hover) {
        style.backgroundColor = this.escapeBadCharacters(
          this.hoverBackgroundColor
        );
      } else {
        style.backgroundColor = this.escapeBadCharacters(this.backgroundColor);
      }

      if (!lodash.isNil(this.width)) {
        style.width = this.escapeBadCharacters(this.width);
      }

      if (!lodash.isNil(this.height)) {
        style.height = this.escapeBadCharacters(this.height);
      }

      if (!lodash.isNil(this.fontSize)) {
        style.fontSize = this.escapeBadCharacters(this.fontSize);
      }

      if (!lodash.isNil(this.textAlign)) {
        style.textAlign = this.escapeBadCharacters(this.textAlign);
      }

      if (!lodash.isNil(this.padding)) {
        style.padding = this.escapeBadCharacters(this.padding);
      }

      if (!lodash.isNil(this.margin)) {
        style.margin = this.escapeBadCharacters(this.margin);
      }

      if (!lodash.isNil(this.font)) {
        style.font = this.escapeBadCharacters(this.font);
      } else {
        const fontSize = lodash.isNil(this.fontSize)
          ? "14px"
          : this.escapeBadCharacters(this.fontSize);

        style.font = `bold ${fontSize} Helvetica, Arial, sans-serif`;
      }

      if (!lodash.isNil(this.borderRadius)) {
        style.borderRadius = this.escapeBadCharacters(this.borderRadius);
      }

      return style;
    },
    paymentBlockStyle() {
      const responseValues = this.ctaStyle;
      const width = lodash.get(responseValues, "width", null);
      const margin = lodash.get(responseValues, "margin", null);
      const style = {};

      if (!lodash.isNil(width)) {
        style.width = this.escapeBadCharacters(width);
      }

      if (!lodash.isNil(margin)) {
        style.margin = this.escapeBadCharacters(margin);
      }

      return style;
    },
    paymentStyle() {
      const style = {
        color: this.escapeBadCharacters(this.fontColor),
      };

      if (!lodash.isNil(this.fontSize)) {
        style.fontSize = this.escapeBadCharacters(this.fontSize);
      }

      if (!lodash.isNil(this.textAlign)) {
        style.textAlign = this.escapeBadCharacters(this.textAlign);
      }

      if (!lodash.isNil(this.borderRadius)) {
        style.borderRadius = this.escapeBadCharacters(this.borderRadius);
      }

      return style;
    },
    paymentBtnStyle() {
      // styling for finance & lease payment CTA only
      const responseValues = this.ctaStyle;
      const color = lodash.get(responseValues, "color", "#C3002F");
      const fontSize = lodash.get(responseValues, "fontSize", null);
      const font = lodash.get(responseValues, "font", null);
      const fontWeight = lodash.get(responseValues, "fontWeight", null);
      const textAlign = lodash.get(responseValues, "textAlign", null);
      const height = lodash.get(responseValues, "height", null);
      const padding = lodash.get(responseValues, "padding", null);
      const backgroundColor = lodash.get(
        responseValues,
        "backgroundColor",
        null
      );
      const hoverBackgroundColor = lodash.get(
        responseValues,
        "hoverBackgroundColor",
        null
      );

      const style = {
        color: this.escapeBadCharacters(color),
      };

      if (!lodash.isNil(fontSize)) {
        style.fontSize = this.escapeBadCharacters(fontSize);
      }

      if (!lodash.isNil(font)) {
        style.fontFamily = this.escapeBadCharacters(font);
      } else {
        const fontSize =
          lodash.isNil(fontSize) || fontSize === "0"
            ? "14px"
            : this.escapeBadCharacters(fontSize);

        style.fontFamily = `bold ${fontSize} Helvetica, Arial, sans-serif`;
      }

      if (!lodash.isNil(fontWeight)) {
        style.fontWeight = this.escapeBadCharacters(fontWeight);
      }

      if (!lodash.isNil(textAlign)) {
        style.textAlign = this.escapeBadCharacters(textAlign);
      }

      if (!lodash.isNil(height)) {
        style.height = this.escapeBadCharacters(height);
      }

      if (!lodash.isNil(padding)) {
        style.padding = this.escapeBadCharacters(padding);
      }

      if (this.hover) {
        style.backgroundColor = this.escapeBadCharacters(hoverBackgroundColor);
        style.color = "#fff";
      } else {
        style.backgroundColor = this.escapeBadCharacters(backgroundColor);
      }
      return style;
    },
    displayAllowed() {
      const responseValues = this.ctaStyle;

      if (this.details) {
        const inDetailsPage = lodash.get(
          responseValues,
          "inDetailsPage",
          false
        );
        return inDetailsPage;
      } else {
        const inListingPage = lodash.get(
          responseValues,
          "inListingPage",
          false
        );
        return inListingPage;
      }
    },
    primaryCTAStyle() {
      const responseValues = this.ctaStyle;
      const width = lodash.get(responseValues, "width", null);
      const height = lodash.get(responseValues, "height", null);
      const fontSize = lodash.get(responseValues, "fontSize", null);
      const fontWeight = lodash.get(responseValues, "fontWeight", null);
      const textAlign = lodash.get(responseValues, "textAlign", null);
      const padding = lodash.get(responseValues, "padding", null);
      const margin = lodash.get(responseValues, "margin", null);
      const font = lodash.get(responseValues, "font", null);
      const borderRadius = lodash.get(responseValues, "borderRadius", null);

      const style = {
        color: this.escapeBadCharacters(this.fontColor),
      };

      if (this.hover) {
        style.backgroundColor = this.escapeBadCharacters(
          this.hoverBackgroundColor
        );
      } else {
        style.backgroundColor = this.escapeBadCharacters(this.backgroundColor);
      }

      if (!lodash.isNil(width)) {
        style.width = this.escapeBadCharacters(width);
      }

      if (!lodash.isNil(fontWeight)) {
        style.fontWeight = this.escapeBadCharacters(fontWeight);
      }

      if (!lodash.isNil(height)) {
        style.height = this.escapeBadCharacters(height);
      }

      if (!lodash.isNil(fontSize)) {
        style.fontSize = this.escapeBadCharacters(fontSize);
      }

      if (!lodash.isNil(textAlign)) {
        style.textAlign = this.escapeBadCharacters(textAlign);
      }

      if (!lodash.isNil(padding)) {
        style.padding = this.escapeBadCharacters(padding);
      }

      if (!lodash.isNil(margin)) {
        style.margin = this.escapeBadCharacters(margin);
      }

      if (!lodash.isNil(font)) {
        style.fontFamily = this.escapeBadCharacters(font);
      } else {
        const fontSize =
          lodash.isNil(fontSize) || fontSize === "0"
            ? "14px"
            : this.escapeBadCharacters(fontSize);

        style.fontFamily = `bold ${fontSize} Helvetica, Arial, sans-serif`;
      }

      if (!lodash.isNil(borderRadius)) {
        style.borderRadius = this.escapeBadCharacters(borderRadius);
      }

      return style;
    },
    secondaryBtnStyle() {
      const responseValues = this.ctaStyle;
      const color = lodash.get(responseValues, "color", "#C3002F");
      const fontSize = lodash.get(responseValues, "fontSize", null);
      const fontWeight = lodash.get(responseValues, "fontWeight", null);
      const font = lodash.get(responseValues, "font", null);
      const textAlign = lodash.get(responseValues, "textAlign", null);
      const height = lodash.get(responseValues, "height", null);
      const padding = lodash.get(responseValues, "padding", null);
      const backgroundColor = lodash.get(
        responseValues,
        "backgroundColor",
        null
      );
      const hoverBackgroundColor = lodash.get(
        responseValues,
        "hoverBackgroundColor",
        null
      );
      const borderRadius = lodash.get(responseValues, "borderRadius", null);

      const style = {
        color: this.escapeBadCharacters(color),
      };

      if (!lodash.isNil(fontSize)) {
        style.fontSize = this.escapeBadCharacters(fontSize);
      }

      if (!lodash.isNil(font)) {
        style.fontFamily = this.escapeBadCharacters(font);
      } else {
        const fontSize =
          lodash.isNil(fontSize) || fontSize === "0"
            ? "14px"
            : this.escapeBadCharacters(fontSize);

        style.fontFamily = `bold ${fontSize} Helvetica, Arial, sans-serif`;
      }

      if (!lodash.isNil(textAlign)) {
        style.textAlign = this.escapeBadCharacters(textAlign);
      }

      if (!lodash.isNil(height)) {
        style.height = this.escapeBadCharacters(height);
      }

      if (!lodash.isNil(padding)) {
        style.padding = this.escapeBadCharacters(padding);
      }

      if (!lodash.isNil(fontWeight)) {
        style.fontWeight = this.escapeBadCharacters(fontWeight);
      }

      if (this.hover) {
        style.backgroundColor = this.escapeBadCharacters(hoverBackgroundColor);
        style.color = "#fff";
      } else {
        style.backgroundColor = this.escapeBadCharacters(backgroundColor);
      }

      if (!lodash.isNil(borderRadius)) {
        style.borderRadius = this.escapeBadCharacters(borderRadius);
      }

      return style;
    },
    isDefaulted() {
      const response = this.ctaStyle;
      const value = lodash.get(response, "name", null);
      const isDefaulted = lodash.isNil(value);
      return isDefaulted;
    },
    // PLACEHOLDER |  PP-374 | Nissan B@H | T1 - Buy@Home Overlay
    // programTenantStyle() {
    //   return {
    //     color: '#FFFFFF'
    //   };
    // },
  },
  mounted() {
    const that = this;
    window.setTimeout(() => that.$emit("onload"), 1);
  },
  methods: {
    onClick() {
      if (!this.enabled) {
        return false;
      }

      const url = this.statusResponse.entryPoint;
      if (this.overlayEnabled) {
        if (this.thirdPartyCookiesEnabled) {
          // If third party cookies are enabled, emit event to open iframe
          this.$emit("openModal");
        } else {
          // If third party cookies are not enabled, redirect URL in the same window tab
          window.open(url, "_self");
        }
      } else {
        // Overlay is not enabled, open in new window tab
        window.open(url, "_blank");
      }
    },
    escapeBadCharacters(value) {
      if (lodash.isNil(value)) {
        return value;
      }
      return value.replace(/[\u201D]/g, "");
    },
    capitalized(name) {
      const capitalizedFirst = name[0].toUpperCase();
      const rest = name.slice(1);

      return capitalizedFirst + rest;
    },
  },
};
</script>

<style scoped lang="scss">
.buy-button {
  position: relative;
  border-radius: 0;
  color: white;
  border: none;
  cursor: pointer;

  &:not(:defined) {
    display: none;
  }

  svg {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 1em;
    height: 1em;
    user-select: none;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }
}

.secondary-cs-button {
  color: #c3002f;
  border: #e7e7e7 !important;
  background-color: #e7e7e7;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
  width: 100%;
}

.secondary-cs-button:hover {
  background-color: darken(#e7e7e7, 10%);
}
.monthly-payment {
  color: #c3002f;
  font-family: "Arial";
  font-size: 40px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 20px;
}

.monthly-label {
  color: #717171 !important;
  font-size: 0.5em;
  font-style: italic;
}

.outlined-div {
  display: block;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-end: 0;
  min-inline-size: min-content;
  border-image: initial;
  text-align: center;
  border: 2px solid #cecece;
  padding: 0;
  margin: 0;
}

.outlined-div-label {
  display: block;
  padding-inline-start: 2px;
  padding-inline-end: 2px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  font-size: 16px;
  font-family: Arial;
  text-align: center;
  color: #717171;
  font-style: italic;
  font-weight: 200;
}
</style>
