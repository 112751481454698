import axios from "axios";

export default new (class ApiClient {
  constructor() {
    this.httpClient = axios.create();
  }

  get(path, params) {
    return this.httpClient.get(path, { params });
  }
})();
