<template>
  <div v-if="enabled" class="cta-container">
    <!-- Image button when imageUrl is provided -->
    <button
      v-if="hasImageUrl && displayAllowed"
      class="image-button"
      :style="appliedStyles"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <img :src="ctaData.style.imageUrl" :alt="btnName" class="cta-image" />
    </button>

    <!-- Text button when no imageUrl is provided -->
    <button
      v-else-if="displayAllowed"
      class="cta-button"
      :style="appliedStyles"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      {{ btnName }}
    </button>
  </div>
</template>

<script>
import lodashGet from "lodash/get";
import lodashIsNil from "lodash/isNil";
import "./showComponentOnLoad";

export default {
  name: "CtaButton",
  props: {
    ctaData: {
      type: Object,
      required: true,
    },
    details: {
      type: Boolean,
      required: false,
      default: false,
    },
    dev: {
      type: Boolean,
      required: false,
      default: false,
    },
    test: {
      type: Boolean,
      required: false,
      default: false,
    },
    thirdPartyCookiesEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    enabled() {
      if (this.test) {
        return true;
      }

      const active = lodashGet(this.ctaData, "active", false);
      const domain = lodashGet(this.ctaData, "domain", null);

      let isValidDomain = !lodashIsNil(domain);
      if (
        isValidDomain &&
        this.dev === false &&
        domain.indexOf("localhost") !== -1
      ) {
        // eslint-disable-next-line
        console.log("cs-buy-widget invalid domain:", domain);
        isValidDomain = false;
      }

      return active === true && isValidDomain;
    },
    btnName() {
      return lodashGet(this.ctaData, "style.name", "");
    },
    hasImageUrl() {
      return (
        lodashGet(this.ctaData, "style.imageUrl", null) !== null &&
        lodashGet(this.ctaData, "style.imageUrl", "").trim() !== ""
      );
    },
    displayAllowed() {
      const style = this.ctaData.style;

      if (this.details) {
        return lodashGet(style, "inDetailsPage", false);
      } else {
        return lodashGet(style, "inListingPage", false);
      }
    },
    appliedStyles() {
      const style = this.ctaData.style;
      const buttonStyle = {
        height: this.escapeBadCharacters(lodashGet(style, "height", "auto")),
        width: this.escapeBadCharacters(lodashGet(style, "width", "auto")),
        backgroundColor: this.escapeBadCharacters(
          this.hover
            ? lodashGet(style, "hoverBackgroundColor", "#9d0026")
            : lodashGet(style, "backgroundColor", "#c3002f")
        ),
        color: this.escapeBadCharacters(lodashGet(style, "color", "#FFFFFF")),
        fontWeight: this.escapeBadCharacters(
          lodashGet(style, "fontWeight", "normal")
        ),
        fontFamily: this.escapeBadCharacters(
          lodashGet(style, "font", "Arial, sans-serif")
        ),
        fontSize: this.escapeBadCharacters(
          lodashGet(style, "fontSize", "14px")
        ),
        textAlign: this.escapeBadCharacters(
          lodashGet(style, "textAlign", "center")
        ),
        padding: this.escapeBadCharacters(lodashGet(style, "padding", "10px")),
        margin: this.escapeBadCharacters(lodashGet(style, "margin", "0")),
        borderRadius: this.escapeBadCharacters(
          lodashGet(style, "borderRadius", "0")
        ),
      };

      return buttonStyle;
    },
  },
  methods: {
    escapeBadCharacters(value) {
      if (lodashIsNil(value)) {
        return value;
      }
      return value.replace(/[\u201D]/g, "");
    },
  },
};
</script>

<style scoped lang="scss">
.cta-button {
  border: none;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  width: 100%;

  &:focus {
    outline: none;
  }
}

.image-button {
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  display: inline-block;

  &:focus {
    outline: none;
  }
}

.cta-image {
  max-width: 100%;
  height: auto;
  display: block;
}
</style>
