const cookieTest = (iFrameUri, callBack) => {
  let messageHandler = (event) => {
    const data = event.data;
    const clonedData = { ...data };
    callBack(clonedData);

    // Check if the received message has the key cscookieresult
    if ("cscookieresult" in clonedData) {
      // remove event listener after receiving cookie test result
      window.removeEventListener("message", messageHandler);
      document.body.removeChild(frame);
    }
  };
  window.addEventListener("message", messageHandler);
  const frame = document.createElement("iframe");
  frame.id = "cookietestiframe";
  frame.src = iFrameUri;
  frame.sandbox = "allow-scripts allow-same-origin";
  frame.style = `display:none`;
  // frame.onload = (e) => {
  //   frame.contentWindow.postMessage(JSON.stringify({ 'test': 'cookie' }), '*');
  // }
  document.body.appendChild(frame);
};

export default cookieTest;
