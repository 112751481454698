window.document.addEventListener("DOMContentLoaded", () => {
  const csBuyWidgets = document.getElementsByTagName("cs-buy-widget-v2");
  if (csBuyWidgets.length > 0) {
    csBuyWidgets[0].addEventListener("onload", function () {
      for (let csBuyWidget of csBuyWidgets) {
        csBuyWidget.style.display = "";
      }
    });
  }
});

window.addEventListener("stateChangeSuccess", () => {
  setTimeout(function () {
    const csBuyWidgets = document.getElementsByTagName("cs-buy-widget-v2");
    if (csBuyWidgets.length > 0) {
      for (let csBuyWidget of csBuyWidgets) {
        csBuyWidget.vueComponent.onCreated();
      }
    }
  }, 0);
});
